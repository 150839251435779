<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span v-if="regenerate">파티 재등록하기</span>
            <span v-else>파티 편집하기</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">카테고리</div>
                  <div class="form_in">
                    <label class="select">
                      <select
                        class="disabled"
                        v-model="partyInfo.categoryNm"
                        disabled
                      >
                        <option
                          v-for="(item, index) in category"
                          :key="index"
                          :value="item.categoryNm"
                          >{{ item.categoryNm }}</option
                        >
                      </select>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">모집 인원</div>
                  <div class="form_in">
                    <label class="select">
                      <select
                        class="disabled"
                        v-model="partyInfo.recruitNum"
                        disabled
                      >
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="6">6</option>
                        <option :value="7">7</option>
                        <option :value="8">8</option>
                        <option :value="9">9</option>
                        <option :value="10">10</option>
                        <option :value="'acntTransfer'">계정양도</option>
                      </select>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">파티명</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="파티명을 입력해 주세요(100자 이내)"
                        v-model="partyInfo.title"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">이용 기간</div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          class="disabled"
                          type="number"
                          placeholder="기간 입력 후 자동계산"
                          v-model="partyInfo.usePeriod"
                          :min="0"
                          disabled
                        />
                      </label>
                      <div
                        class="form_txt"
                        v-show="partyInfo.unlimitedCheckYn === 'N'"
                      >
                        <span
                          class="color"
                          v-if="
                            !$Util.isEmpty(partyInfo.usePeriod) &&
                              partyInfo.usePeriod !== '표시없음'
                          "
                          >{{
                            $dayjs(partyInfo.useDt)
                              .subtract(1, 'day')
                              .format('YYYY.MM.DD')
                          }}</span
                        >
                        <span class="color" v-else> - </span>
                        까지
                      </div>
                    </div>
                    <!--                    <div class="hint">-->
                    <!--                      * 판매 상품은 등록 당일부터 정상 서비스 제공되어야 합니다.-->
                    <!--                    </div>-->
                    <label
                      v-if="partyInfo.acntTransferYn === 'Y'"
                      class="checkbox"
                    >
                      <input
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        v-model="partyInfo.unlimitedCheckYn"
                        :checked="partyInfo.unlimitedCheckYn === 'Y'"
                        disabled
                      />
                      <span class="label"
                        ><span>무제한 또는 기간 별도 표시</span></span
                      >
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">
                    {{
                      partyInfo.acntTransferYn === 'N'
                        ? '인당 일 판매가'
                        : '총 판매가'
                    }}
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          :class="{ disabled: partyInfo.autoExtension === 'Y' }"
                          :disabled="partyInfo.autoExtension === 'Y'"
                          type="number"
                          :placeholder="
                            partyInfo.acntTransferYn === 'N'
                              ? '인당 일 판매가'
                              : '총 판매가'
                          "
                          :value="partyInfo.dailyPrice"
                          @input="maskingDailyPriceNumber"
                          :min="0"
                        />
                      </label>
                      <div class="form_txt">
                        총<span class="color">
                          {{
                            $Util.formatNumber2(
                              partyInfo.acntTransferYn === 'N'
                                ? partyInfo.dailyPrice * partyInfo.usePeriod
                                : partyInfo.dailyPrice,
                              { unit: '' }
                            )
                          }} </span
                        >원
                      </div>
                    </div>
                    <!--                    <div class="hint">-->
                    <!--                      * 판매 가격은 등록일로부터 하루씩 차감되어 판매됩니다.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 다른 가격을 원하시면 파티 생성 정보를 변경하세요.-->
                    <!--                    </div>-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">예상 플랫폼 이용료(일)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        :value="
                          $Util.formatNumber2(aDaySellerFee, {
                            unit: ' 원'
                          })
                        "
                        disabled
                      />
                    </label>
                  </div>
                  <div class="hint">
                    * 플랫폼 이용료 할인이벤트 진행 중({{
                      Math.floor(sellerFee * 100)
                    }}% 적용)
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">정산 예정금액</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        :value="
                          $Util.formatNumber2(expectedTotalAmount, {
                            unit: ' 원'
                          })
                        "
                        disabled
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">파티 소개 글 및 주의사항</div>
                  <div class="form_in">
                    <label>
                      <textarea
                        placeholder="판매하실 구독상품에 대한 상세설명을 기입해주세요. 자세한 이용방법 및 제공되는 혜택 관련 안내사항, 주의사항 등"
                        :maxlength="1000"
                        v-model="partyInfo.precautions"
                      ></textarea>
                    </label>
                    <!--                    <div class="hint">-->
                    <!--                      * 파티원들이 확인할 수 있도록 간단한 파티 소개를 입력해-->
                    <!--                      주세요.-->
                    <!--                    </div>-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">공유 계정</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        :class="{ disabled: partyInfo.shareYn === 'Y' }"
                        type="text"
                        placeholder="아이디 입력"
                        v-model="partyInfo.shareId"
                        :disabled="partyInfo.shareYn === 'Y'"
                        :maxlength="40"
                      />
                    </label>
                    <label class="input">
                      <input
                        :class="{ disabled: partyInfo.shareYn === 'Y' }"
                        type="text"
                        placeholder="패스워드 입력"
                        v-model="partyInfo.sharePw"
                        :disabled="partyInfo.shareYn === 'Y'"
                        :maxlength="40"
                      />
                    </label>
                    <label v-if="autoExtension === 'N'" class="checkbox">
                      <input
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        v-model="partyInfo.shareYn"
                        :checked="partyInfo.shareYn === 'Y'"
                      />
                      <span class="label"
                        ><span
                          >파티장이 파티원에 위즈톡으로 개별 안내합니다.</span
                        ></span
                      >
                    </label>
                    <!--                    <div class="hint">-->
                    <!--                      * 작성한 파티장 정보는 결제완료 회원만 노출됩니다.-->
                    <!--                      안심하세요.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 로그인 정보 및 추가적인 전달 사항도 함께 전달해주시면-->
                    <!--                      편리합니다.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 공유 계정 변경시 자동으로 파티원들에 메신저로-->
                    <!--                      공유됩니다.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 위즈톡 개별 안내는 파티원 결제시간 기준 3시간 이내-->
                    <!--                      (심야제외) 안내해주세요.-->
                    <!--                    </div>-->
                  </div>
                </li>
                <li
                  v-if="
                    partyInfo.acntTransferYn === 'Y' ||
                      partyInfo.categoryId == '1004' ||
                      partyInfo.categoryId == '1100'
                  "
                  class="form_li"
                >
                  <div class="form_ttl essen">파티원 정보요청</div>
                  <div class="form_in">
                    <div class="inline">
                      <div
                        class="option_btn"
                        :class="{ on: partyInfo.reqKakao == '1' }"
                        @click="
                          () =>
                            (partyInfo.reqKakao =
                              partyInfo.reqKakao == '0' ? '1' : '0')
                        "
                      >
                        <button>카카오톡ID</button>
                      </div>
                      <div
                        class="option_btn"
                        :class="{ on: partyInfo.reqPhone == '1' }"
                        @click="
                          () =>
                            (partyInfo.reqPhone =
                              partyInfo.reqPhone == '0' ? '1' : '0')
                        "
                      >
                        <button>휴대폰번호</button>
                      </div>
                      <div
                        class="option_btn"
                        :class="{ on: partyInfo.reqEmail == '1' }"
                        @click="
                          () =>
                            (partyInfo.reqEmail =
                              partyInfo.reqEmail == '0' ? '1' : '0')
                        "
                      >
                        <button>이메일</button>
                      </div>
                    </div>
                    <div class="hint">
                      추가 정보 전달을 위해 필요한 파티원 정보를 선택해주세요.
                    </div>
                    <div class="hint">
                      파티원이 작성한 정보는 생성파티 상세에서 확인 가능합니다.
                    </div>
                  </div>
                </li>
                <!-- <li class="form_li" v-if="partyInfo.acntTransferYn === 'Y'">
                  <div class="form_ttl ">정보요청</div>
                  <div class="form_in">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        v-model="partyInfo.optionYn"
                        :checked="partyInfo.optionYn === 'Y'"
                      />
                      <span class="label"
                        ><span
                          >구매자가 주소 또는 휴대폰 번호 등이 필요한 경우
                          구매자에게 추가 정보를 요청합니다.</span
                        ></span
                      >
                    </label>
                    <label class="input">
                      <input
                        type="text"
                        placeholder="요청할 내용을 입력하세요. (20자)"
                        v-model="partyInfo.reqOptionMsg"
                        maxlength="20"
                      />
                    </label>
                  </div>
                </li> -->
                <!--                <li class="form_li">-->
                <!--                  <div class="form_ttl">주의사항</div>-->
                <!--                  <div class="form_in">-->
                <!--                    <label>-->
                <!--                      <textarea-->
                <!--                        placeholder="주의사항 500자 이내 입력"-->
                <!--                        v-model="partyInfo.precautions"-->
                <!--                        :maxlength="500"-->
                <!--                      ></textarea>-->
                <!--                    </label>-->
                <!--                    <div class="hint">-->
                <!--                      * 파티원들의 참여 관련 주의 사항을 입력해 주세요.-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </li>-->
                <li class="form_li">
                  <div class="form_ttl">검색어 설정</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="검색어, 검색어, 검색어, 검색어, 검색어"
                        v-model="partyInfo.searchWord"
                        :maxlength="100"
                      />
                    </label>
                    <!--                    <div class="hint">-->
                    <!--                      * 검색어, 식으로 검색어 뒤에 콤마를 찍어서 입력해주세요.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 검색어는 5개 미만으로 등록해야 검색 결과가 좋습니다.-->
                    <!--                    </div>-->
                    <!--                    <div class="hint">-->
                    <!--                      * 검색어가 너무 길면 검색기간이 길어지거나 검색이 되지-->
                    <!--                      않을수 있습니다.-->
                    <!--                    </div>-->
                  </div>
                </li>
                <li
                  class="form_li"
                  v-if="
                    regenerate ||
                      (partyInfo.acntTransferYn === 'N' &&
                        partyInfo.autoExtension === 'Y')
                  "
                >
                  <div class="form_ttl essen">파티 자동 연장</div>
                  <div class="form_in">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :true-value="'Y'"
                        :false-value="'N'"
                        v-model="autoExtension"
                        :checked="autoExtension === 'Y'"
                      />
                      <span class="label" @click.prevent="fnCheckAutoExtension"
                        ><span>자동 연장</span></span
                      >
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnCancel">취소</button>
              <button class="btn" v-if="regenerate" @click="fnRegenerate">
                파티 재등록
              </button>
              <button class="btn" v-else @click="fnEdit">수정</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <div>
                    * 플랫폼 이용료는 총 판매금액의 7% 입니다.(총 판매가 * 0.07)
                  </div>
                  <div>
                    * 개별 공급자가 등록한 나눔 내용 및 거래에 대한 책임은
                    공급자가 부담하며, 이에 따라서 공급자가 진행하는 서비스에
                    대해서 위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한
                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장하며,
                    위즈캐시로 환불 진행됩니다.
                  </div>
                  <div>
                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한
                    사용 불가가 확인 된 경우 100%금액 현금 환불)
                  </div>
                  <div class="sec_color">
                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,
                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한
                    상품인지 확인하고 거래해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-created-party-edit-index',
  props: {
    regenerate: { type: String }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
