import { reactive, toRefs, getCurrentInstance, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    partyNo: route.query.partyNo,
    category: [],
    sellerFee: 0,
    computedSellerFee: computed(() =>
      state.partyInfo.acntTransferYn === 'N'
        ? Math.floor(state.sellerFee * state.partyInfo.dailyPrice) *
          state.partyInfo.usePeriod
        : Math.floor(state.sellerFee * state.partyInfo.dailyPrice)
    ),
    amount: computed(() =>
      state.partyInfo.acntTransferYn === 'N'
        ? state.partyInfo.dailyPrice * state.partyInfo.usePeriod
        : state.partyInfo.dailyPrice
    ),
    aDaySellerFee: computed(
      () =>
        !proxy.$Util.isEmpty(state.partyInfo) &&
        Math.floor(Number(state.partyInfo.dailyPrice) * Number(state.sellerFee))
    ),
    expectedTotalAmount: computed(() => state.amount - state.computedSellerFee),
    serverDt: '',
    autoExtension: 'Y',
    changeAutoExtension: false,
    partyInfo: {},
    regenerate: Boolean(props.regenerate) || false
  })
  const { fnMyInfo } = CommonFunction(state)
  const fnCategoryList = async () => {
    const res = await proxy.$PartySvc.postPartyAddForm()
    if (res.resultCode === '0000') {
      state.category = res.category
      state.serverDt = res.serverDt
      state.sellerFee = res.sellerFee
    } else {
      alert(res.resultMsg)
    }
  }
  const fnInfo = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postPartyInfo(params)
    if (res.resultCode === '0000') {
      state.partyInfo = res.partyInfo
      state.autoExtension = res.partyInfo.autoExtension
      if (state.partyInfo.acntTransferYn === 'Y') {
        state.partyInfo.recruitNum = 'acntTransfer'
      }
    } else {
      alert(res.resultMsg)
      await router.go(-1)
    }
  }
  const fnRegenerate = async () => {
    if (!(await isValid())) return
    if (!confirm('재등록 하시겠습니까?')) return
    const params = { ...state.partyInfo }
    params.useDt = proxy
      .$dayjs()
      .add(params.usePeriod, 'day')
      .format('YYYYMMDD')
    params.autoExtension = state.autoExtension
    delete params.categoryNm
    delete params.partyNo
    const res = await proxy.$PartySvc.postPartyAdd(params)
    if (res.resultCode === '0000') {
      alert('파티가 정상적으로 재등록되었습니다.')
      await router.push({ name: 'my-page-created-party-index' })
    } else {
      alert(res.resultMsg)
    }
  }

  const isValid = async () => {
    const reqOptions = {
      reqKakao: state.partyInfo.reqKakao,
      reqPhone: state.partyInfo.reqPhone,
      reqEmail: state.partyInfo.reqEmail
    }

    if (
      proxy.$Util.isEmpty(state.userData) ||
      proxy.$Util.isEmpty(state.userData.authType) ||
      Number(state.userData.authType) < 4
    ) {
      alert(
        '파티모집을 위해 내 정보 수정 > 휴대폰 인증 및 계좌번호 등록을 해주세요.'
      )
      await fnMyInfo()
      return false
    }
    if (proxy.$Util.isEmpty(state.partyInfo.title)) {
      alert('제목을 입력해주세요.')
      return false
    }
    let price = Number(state.partyInfo.dailyPrice)
    // TODO 여기 체크
    if (
      state.partyInfo.unlimitedCheckYn === 'N' &&
      state.partyInfo.acntTransferYn === 'N'
    ) {
      // 무제한 아닌경우
      price =
        Number(state.partyInfo.dailyPrice) * Number(state.partyInfo.usePeriod)
    }
    if (price < 1000) {
      alert('판매가는 1,000원 이상 등록해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.partyInfo.precautions)) {
      alert('파티 소개글 및 주의사항을 입력해주세요.')
      return false
    }
    if (state.partyInfo.shareYn === 'N') {
      if (proxy.$Util.isEmpty(state.partyInfo.shareId)) {
        alert('공유 계정 아이디를 입력해주세요.')
        return false
      }
      if (proxy.$Util.isEmpty(state.partyInfo.sharePw)) {
        alert('공유 계정 패스워드를 입력해주세요.')
        return false
      }
    }
    if (state.partyInfo.optionYn === 'Y') {
      if (proxy.$Util.isEmpty(state.partyInfo.reqOptionMsg)) {
        alert('요청할 내용을 입력해주세요.')
        return false
      }
    }
    if (
      (state.partyInfo.acntTransferYn === 'Y' ||
        state.partyInfo.categoryId == '1004' ||
        state.partyInfo.categoryId == '1100') &&
      Object.keys(reqOptions).every(key => reqOptions[key] == 0)
    ) {
      alert('파티원 정보요청을 선택해주세요.')
      return false
    }

    return true
  }

  const fnEdit = async () => {
    if (!(await isValid())) return
    if (state.partyInfo.autoExtension === 'Y' && state.autoExtension === 'N') {
      if (
        confirm(
          '자동연장 설정을 해제 하시겠습니까?\n해지 후 재설정은 불가능합니다.'
        )
      ) {
        state.partyInfo.autoExtension = 'N'
        state.changeAutoExtension = true
      } else {
        state.partyInfo.autoExtension = 'Y'
        return
      }
    } else {
      if (!confirm('수정하시겠습니까?')) return
    }
    const params = { ...state.partyInfo }
    delete params.categoryNm
    delete params.recruitNum
    delete params.acntTransferYn
    delete params.useDt
    delete params.usePeriod
    const res = await proxy.$MyPageSvc.postPartyUpdate(params)
    if (res.resultCode === '0000') {
      if (
        state.changeAutoExtension &&
        state.partyInfo.autoExtension === 'N' &&
        state.autoExtension === 'N'
      ) {
        alert('해제되었습니다.\n이용기간 만료 후 해당파티는 마감됩니다.')
      } else {
        alert('수정되었습니다.')
      }
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnCancel = async () => {
    await router.go(-1)
  }
  const fnCheckAutoExtension = () => {
    state.autoExtension = state.autoExtension === 'N' ? 'Y' : 'N'
  }
  /** watch **/
  watch(
    () => state.partyInfo.recruitNum,
    val => {
      if (val === 'acntTransfer') {
        // 계정양도
        state.partyInfo.acntTransferYn = 'Y'
      } else {
        state.partyInfo.acntTransferYn = 'N'
      }
    }
  )
  watch(
    () => state.partyInfo.shareYn,
    val => {
      if (val === 'Y') {
        state.partyInfo.shareId = ''
        state.partyInfo.sharePw = ''
      }
    }
  )
  watch(
    () => state.partyInfo.shareId,
    val => {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
      if (reg.exec(val) !== null) {
        state.partyInfo.shareId = ''
      }
    }
  )
  watch(
    () => state.partyInfo.sharePw,
    val => {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
      if (reg.exec(val) !== null) {
        state.partyInfo.sharePw = ''
      }
    }
  )

  watch(
    () => state.autoExtension,
    val => {
      if (val === 'Y') {
        state.partyInfo.shareYn = 'N'
      }
    }
  )

  const maskingDailyPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 9) {
      e.target.value = e.target.value.substr(0, 9)
      state.partyInfo.dailyPrice = e.target.value.substr(0, 9)
    } else {
      state.partyInfo.dailyPrice = e.target.value
    }
  }

  const init = async () => {
    await fnCategoryList()
    await fnInfo()
  }
  init()
  return {
    ...toRefs(state),
    fnEdit,
    fnCancel,
    fnRegenerate,
    fnCheckAutoExtension,
    maskingDailyPriceNumber
  }
}
